@font-face {
  font-family: 'Caveat';
  src: url(/fonts/Caveat/Caveat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Caveat';
  src: url(/fonts/Caveat/Caveat-Bold.ttf) format('truetype');
  font-weight: 700;
}

body {
  /* gremlin fucking variables */
  --primary-bg: rgb(139, 129, 94);
  --GremlinFont: 'Caveat';
  --GremlinAlt: 'Inter';

  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background: linear-gradient(0deg,#9598a6 0%,#a28ea1 100%); */
  background: rgb(255,211,139);
  /* background: linear-gradient(0deg, rgba(255,211,139,1) 0%, rgba(209,155,67,1) 43%, rgba(255,165,165,1) 100%); */
  background: linear-gradient(0deg, rgba(255,211,139,1) 0%, rgba(209,155,67,1) 43%, rgba(255,165,165,1) 70%, rgba(149,149,149,1) 89%);

  font: 700 24px/1 var(--GremlinFont);
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}  

.HoldYourTits { 
  -webkit-animation: none !important;
  animation: none !important;
  -webkit-filter: grayscale(80%);
          filter: grayscale(80%);
  cursor: not-allowed;
  color: #000 !important;
}


#WorldWrapper {
  min-height: -webkit-fill-available;
  min-height: 100vh;
  background-attachment: fixed;
  /* filter:contrast(200%); */
}

#WorldWrapper.Boner {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
  /* filter:contrast(200%); */
}

.disabled {
  pointer-events: none !important;
}

#goUnder {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  z-index: 99;
  position: fixed;
  top: 200px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: hsl(1, 100%, 67%);
}

#goUnder.hide {
  transition: opacity .5s ease-in-out;
}

#incCount {
  background-color: red !important;
  font-size: 32px;
  height: 64px;
  width: 64px;
  border: 4px solid red;
  border-radius: 8px;
  font-weight: 800;
}  

/* #goUnder:focus {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
} */

button, .button {
  font-family: inherit;
  color: inherit;
  font-size: 32px;
  /* height: 56px; */
  cursor: pointer;
  position: relative;
  width: 100%;
  text-align: center;
}

#goUnder { 
  border: 2px solid #000;
  padding: 12px 16px 8px;
  border-radius: 9px 7px 5px 8px;
  box-shadow: 0 2px 0 #000;
  will-change: filter;
  -webkit-filter: drop-shadow(4px 6px 0 rgba(0,0,0,0.125));
          filter: drop-shadow(4px 6px 0 rgba(0,0,0,0.125));  
  font-size: 31px;
  font-weight: 700;
}

#AboveGround {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  z-index: 0;
  position: relative;
  /* padding: 0 40px; */
  padding: 0;
  margin: 0;
  /* background-color: bisque; */
}

#BigHardTing #greenShits>* {
  position: absolute;
  inset: 0;
  z-index: 3;
}

#BigHardTing #burner {
  position: absolute;
  inset: 0;
  -webkit-animation: blazin 2s ease-in-out infinite;
  animation: blazin 2s ease-in-out infinite;
}

#BigHardTing #scaffy_fire {
  position: absolute;
  inset: 0;
  -webkit-animation: blazin2 1s ease-in-out infinite;
  animation: blazin2 1s ease-in-out infinite;
}

#BigHardTing #gobbie_toast {
  position: absolute;
  inset: 0;
  -webkit-animation: blazin2 3s ease-in-out infinite;
  animation: blazin2 3s ease-in-out infinite;
}

#BigHardTing #mount_burn {
  position: absolute;
  inset: 0;
  -webkit-animation: blazin3 5s ease-in-out infinite;
  animation: blazin3 5s ease-in-out infinite;
  z-index: 4;
}


#BigHardTing {
  position: absolute;
  width: 100%;
  height: 100%;

}

#BigHardTing img {
  width: 50%;
  height: 100%;

}

#BigHardTing_Base {
  z-index: 1;
}

#BigHardTing>* {
  position: absolute;
  inset: 0;
}

#gobbieCitee {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: all 1.5s ease-in-out;
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  -webkit-transform: scale(.75);
          transform: scale(.75);
}

#gobbieCitee img {
  width: 100%;
  margin-top: auto;
  object-fit: contain;
}

#gobbieCitee>* {
  position: absolute;
  inset: 0;
}

#FT_midz_burnz { 
  -webkit-animation: blazin 4s ease-in-out infinite;
  -webkit-transition: blazin 4s ease-in-out infinite;
  animation: blazin 4s ease-in-out infinite;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}

#FT_hippee_citee_w_toast { 
  -webkit-animation: blazin2 4s ease-in-out infinite;
  -webkit-transition: blazin 4s ease-in-out infinite;
  animation: blazin2 4s ease-in-out infinite;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}

#FT_frontal_burnz { 
  -webkit-animation: blazin 4s ease-in-out infinite;
  -webkit-transition: blazin 4s ease-in-out infinite;
  animation: blazin 4s ease-in-out infinite;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}

#GremlinUnderground { 
  z-index: 800;
  width: 100vw;
  /* background: #000; */
  position: relative;  
}

#gobbie_toastin {
  width: 104px;
  position: absolute;
  top: -72px;
  right: 40px;
  cursor: pointer;
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  z-index: 10000;  
}

#gobbie_toastin img {
  max-width:100%;
}

#GremlinUnderground #rockz {
  /* position: absolute; */
  position: relative;
  inset: 0;
  margin-top: -3.19vw;
  width: 100%;
  height: calc(100% + 2vw);
  object-fit: cover;
  object-position: top;
}

#GremlinUnderground #dirtz {
  /* position: absolute; */
  position: relative;
  inset: 0;
  z-index: -4;
  margin-top: -4vw;
  width: 100%;
  height: calc(100% + 2vw);
  object-fit: cover;
  object-position: top;
}

#GremlinUnderground #lavaz {
  /* position: absolute; */
  position: relative;
  inset: 0;
  z-index: -3;
  margin-top: -5vw;
  width: 100%;
  height: calc(100% + 2vw);
  object-fit: cover;
  /* box-shadow: 0 0 30px 30px rgb(131, 61, 26); */
  border: 1px solid transparent;
  mix-blend-mode: difference;
}

/*
#GremlinUnderground #DaLair {
  position: absolute;
  position: relative;
  min-height: 100vh;
  inset: 0;
  z-index: -3;
  margin-top: -.5vw;
  width: 100%;
  height: calc(100% + 2vw);
  object-fit: cover;
  box-shadow: 0 0 20px 50px rgb(131, 61, 26);
}
*/

#GremlinUnderground #darknezz {
  position: relative;
  background-color: rgb(90, 62, 2);
  z-index: -6;
  /* filter: contrast(50%) !important; */
}

#GremlinUnderground #DaLairHodler {
  /* position: absolute; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: url("/images/GremlinzLair.png") no-repeat;
  background-size: 100%;
  min-height: 100vh;
  height: calc(100% + 2vw);
  inset: 0;
  z-index: -3;
  margin-top: -.5vw;
  max-width: 100%;
  object-fit: cover;
  box-shadow: 0 0 20px 50px rgb(131, 61, 26);
}

#holdGremlinz {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#printGremlinz {
  /* display: flex;
  justify-content: space-between;
  flex-wrap: wrap; */
  min-width: 300px;
  max-width: 600px;
  margin: 0 auto;
  background-color: rgb(0, 0, 0, 50%);
  border: 5px solid white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 16px 40px;
  color: white;
}

#gremlinFlex {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  grid-gap: 4px;
  gap: 4px;
  /* flex-wrap: wrap; */
  justify-content: center;
  align-items: center;
}

#wekm { 
  text-align: center;
  font-size: 36px;
  -webkit-margin-after: 0.5em;
          margin-block-end: 0.5em;
  font-weight: 700;
}

#readDis { 
  -webkit-margin-after: 1em; 
          margin-block-end: 1em;
  /* font-family: Inter; */
  font-size: 26px;
  line-height: 1.5;
  text-align: center;
  font-weight: 700;
}

#GremlinzStuff {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 32px;
  gap: 32px;
  justify-content: space-between;
}

#countzGremlinz {
  position: absolute;
  left: 8px;
  top: 8px;
  background: black;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid white;
  font-size: 16px;
}

#peepGremlinz { 
  display: flex;
  position: relative;
  width: 100%;
  min-width: 250px;
  max-width: 250px;  
}

#seeez {
  width: 100%;
  max-height: 250px;
  border-radius: 8px;
}

#digzGremlin {
  min-width: 300px;
  margin: auto;
}

#CountButtonz {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 32px;
  gap: 32px;
  font-size: 46px;
  font-family: --var(GremlinFont);
  padding: 10px 10px;
  -webkit-margin-after: 20px;
          margin-block-end: 20px;
}

.countz {
  background-color: red !important;
  font-size: 32px;
  height: 64px;
  width: 64px;  
  border: 4px solid red; 
  border-radius: 8px;
  font-weight: 700;
}

#daCost {
  display: flex;
  justify-content: space-between;
  border-top: solid white 1px;
  border-bottom: solid white 1px;
  grid-gap: 16px;
  gap: 16px;
  /* font-size: 24px; */
  font-size: 33px;
  padding: 15px 0px;
  -webkit-margin-after: 20px;
          margin-block-end: 20px;
  /* font-family: Inter; */
  font-weight: 700;
}

#connectDaGremlinz {
  background: hsl(1, 80%, 80%);
  color: red;
  border: 4px solid red;
  border-radius: 8px;
  font-size: 28px;
  font-weight: 700;
  padding: 24px 24px;
  width: 100%;
}

#digzDaGremlinz {
  background-color: red;
  font-size: 26px;
  font-weight: 700;
  border: 4px solid red;
  border-radius: 8px;
  padding: 0px 24px;
  width: 100%;
  max-height: 100px;
}

#digzDaGremlinz span {
  font-size:18px;
  font-weight: 600;
}

#status {
  max-width: 300px;
  text-align: center;
  font-size: 16px;
  padding-top: 5px;
}

#status a {
  color: red;
  text-decoration: none;
}

#gremlinLinkz {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
  padding-top: 10px;
  text-align: center;
}

#gremlinLinkz img {
  width: 100px;
}

#TwatterHash {
  display: flex;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 100%;
  height: 50px;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  font-size: 32px;
}

#noPlan {
  text-align: center;
  font-size: 22px;
}

#noGremlinz  {
  text-align: center;
}

#noGremlinz a {
  color: red;
  text-decoration: none;
  font-size: 42px;
}

#gremlinzzAllDug {
  font-family: --var(GremlinFont);
}

.getFaded {
  pointer-events: none;
  animation: fadeOut ease 1.5s;
  -webkit-animation: fadeOut ease 1.5s;
  -moz-animation: fadeOut ease 1.5s;
  -o-animation: fadeOut ease 1.5s;
  -ms-animation: fadeOut ease 1.5s; 
}

.drunk {
  pointer-events: none;
  -webkit-animation:spin 0.5s linear infinite;
  animation:spin 0.5s linear infinite;
}
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); /* -webkit-transform-origin: -25% 50%; */ } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); /* transform-origin: -25% 50%; */ } }

@keyframes fadeOut{
  0% {
    opacity:1;
    -webkit-transform: rotate(360deg); transform:rotate(360deg);
  }
  25% {
    opacity:1;
    -webkit-transform: rotate(360deg); transform:rotate(360deg);
  }  
  50% {
    opacity:1;
    -webkit-transform: rotate(360deg); transform:rotate(360deg);
  }
  75% {
    opacity:1;
    -webkit-transform: rotate(360deg); transform:rotate(360deg);
  }
  100% {
    opacity:0;

  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity:1;
    -webkit-transform: rotate(360deg); transform:rotate(360deg);
  }
  25% {
    opacity:1;
    -webkit-transform: rotate(360deg); transform:rotate(360deg);
  }  
  50% {
    opacity:1;
    -webkit-transform: rotate(360deg); transform:rotate(360deg);
  }
  75% {
    opacity:1;
    -webkit-transform: rotate(360deg); transform:rotate(360deg);
  }
  100% {
    opacity:0;

  }
}

@-webkit-keyframes flicker-font{
  50%{
      font-style:italic
  }
}
@keyframes flicker-font{
  50%{
      font-style:italic
  }
}

@-webkit-keyframes flicker{
  50%{
      -webkit-transform:rotate(0.5deg);
              transform:rotate(0.5deg);
      font-weight:700
  }
}
@keyframes flicker{
  50%{
      -webkit-transform:rotate(0.5deg);
              transform:rotate(0.5deg);
      font-weight:700
  }
}


@-webkit-keyframes blazin{
  0% {
    opacity: 1;
  }
  25%{
      opacity:.15
  }
  50%{
      opacity:.75
  }
  75%{
      opacity:.55
  }
}
@keyframes blazin{
  0%, 100% {
    opacity: 1;
  }  
  25%{
    opacity:.55
  }
  50%{
      opacity:.75
  }
  75%{
      opacity:.55
  }
}

@-webkit-keyframes blazin2{
  0% {
    opacity: 1;
  }
  25%{
      opacity:.15
  }
  50%{
      opacity:.95
  }
  75%{
      opacity:.55
  }
}
@keyframes blazin2{
  0%, 100% {
    opacity: 1;
  }  
  25%{
    opacity:.55
  }
  50%{
      opacity:.95
  }
  75%{
      opacity:.55
  }
}

@-webkit-keyframes blazin3{
  0%,100% {
    opacity: .2;
  }
  25%{
    opacity:.05
  }
  50%{
      opacity:.25
  }
  75%{
      opacity:.15
  }
}

@keyframes blazin3{
  0%,100% {
    opacity: .4;
  }
  25%{
    opacity:.05
  }
  50%{
      opacity:.25
  }
  75%{
      opacity:.15
  }
}

@media screen and (max-width: 830px) {
  html, body {
    max-width: 100%;
    overflow-x: hidden;
  }  
  #GremlinUnderground #DaLairHodler {
    background-position: center;
    background-size: 120% 100%;
    box-shadow: 0 0 10px 20px rgb(131 61 26);
  }

  #GremlinzStuff {
    justify-content: center;
    max-width: 300px;
  }

  #wekm {
    font-size: 35px;
  }

  #readDis { 
    font-size: 18px;
    line-height: 1.75;
  }

  #GremlinzStuff {
    padding: 10px 10px;
  }

  .countz {
    font-size: 24px;
  }

  #gremlinLinkz img {
    width: 75px;
  }

  #noPlan {
    font-size: 21px;
  }
}

@media screen and (min-width: 1230px) and (max-width: 1520px) {
  #GremlinUnderground #DaLairHodler {
    min-height: 100vh;
  }
}

@media screen and (min-width: 1520px) {
  #GremlinUnderground #DaLairHodler {
    min-height: 150vh;
  }  
}

@media(hover: hover) and (pointer: fine) {
  button:hover, #goUnder:hover {
    -webkit-animation:flicker .25s linear infinite;
    animation:flicker .25s linear infinite;
    background: rgb(169, 104, 0);
    color:#fff;
  }
  
  #goUnder:hover span {
    -webkit-animation:flicker-font .25s linear infinite;
    animation:flicker-font .25s linear infinite;
    text-transform:uppercase
  }

  #gremlinLinkz img:hover {
    -webkit-transform: rotate(-7deg);
            transform: rotate(-7deg);
  }
}
.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

